<template>
	<div class="element-box">
		<b-form @submit.stop.prevent="submit">
			<h5 class="form-header">
				Создание атрибута
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Название атрибута: {{ attribute.name }}<br>
					Код атрибута: {{ attribute.code }}<br>
					<div v-if="namespaceOptions.length > 0 && attribute.namespace_id !== ''">
						Неймспейс атрибута: {{ namespaceOptions.find(x => x.value === attribute.namespace_id).text }}<br>
					</div>
					<div v-if="attribute.scopes.length > 0">
						Области видимости атрибута:
						<div v-for="scope in attribute.scopes" :key="scope">{{ scope }}</div>
					</div>
				</div>
			</b-modal>

			<b-form-group id="name-input-group" label="Название атрибута" label-for="attribute-name-input">
				<b-form-input
						id="attribute-name-input"
						v-model="$v.attribute.name.$model"
						:state="validateState('name')"
						aria-describedby="name-input-attribute-feedback"
						placeholder="Введите название атрибута"
				></b-form-input>
				<b-form-invalid-feedback
						id="name-input-attribute-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="code-input-group" label="Код атрибута" label-for="attribute-code-input">
				<b-form-input
						id="attribute-code-input"
						v-model="$v.attribute.code.$model"
						:state="validateState('code')"
						aria-describedby="code-input-group-feedback"
						placeholder="Введите код атрибута"
				></b-form-input>
				<b-form-invalid-feedback
						id="code-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

            <b-form-group id="description-input-group" label="Описание атрибута"
                          label-for="attribute-description-input">
                <b-form-textarea
                        id="attribute-description-input"
                        v-model="$v.attribute.description.$model"
                        :state="validateState('description')"
						aria-describedby="description-input-group-feedback"
                        placeholder="Введите описание атрибута"
                ></b-form-textarea>
                <b-form-invalid-feedback
                        id="description-input-group-feedback"
                >Обязательное поле.
                </b-form-invalid-feedback>
            </b-form-group>

			<b-form-group id="namespace-input-group" label="Выбор неймспейса атрибута" label-for="namespace-input">
				<b-form-select
						id="namespace-input"
						v-model="attribute.namespace_id"
						:options="namespaceOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="scopes-input-group" label="Области видимости атрибута" label-for="attribute-scopes-input">
				<b-form-tags id="attribute-scopes-input" v-model="attribute.scopes" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onScopeDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>{{ tag }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление областей видимости
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск областей видимости"
										label-for="scope-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchScopesDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchScopes"
											id="scope-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название области видимости"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="scope in scopeAvailableOptions"
									:key="scope"
									@click="onScopeAddClick({ option: scope, addTag: addTag })"
							>{{ scope }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="scopeAvailableOptions.length === 0">
								Нет областей видимости, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToAttributeTable()">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

export default {
	name: 'AttributeCreateForm',
	mixins: [validationMixin],
	validations: {
		attribute: {
			name: {
				required
			},
			code: {
				required
			},
			description: {
				required
			},
			namespace_id: {
				required
			}
		}
	},
	data() {
		return {
			attribute: {
				name: '',
				code: '',
				description: '',
				namespace_id: '',
				scopes: [],
			},
			scopeOptions: [],
			searchScopes: '',

			namespaceOptions: [],
			namespace: '',

			valid: false,
		};
	},
	watch: {
		'attribute': function (val, oldVal) {
			this.checkFields();
		},
		'attribute.name': function (val, oldVal) {
			this.checkFields();
		},
		'attribute.code': function (val, oldVal) {
			this.checkFields();
		},
		'attribute.description': function (val, oldVal) {
			this.checkFields();
		},
		'attribute.namespace_id': function (val, oldVal) {
			this.checkFields();
		},
	},

	methods: {
		...mapActions([
			'CREATE_ATTRIBUTE', 'GET_SCOPES_FROM_API', 'GET_NAMESPACES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),
		checkFields() {
			let check_arr = {
				name: false,
				code: false,
				description: false,
				namespace: false,
			};
			if (this.attribute.name && this.attribute.name.length > 0) {
				check_arr.name = true;
			}
			if (this.attribute.code && this.attribute.code.length > 0) {
				check_arr.code = true;
			}
			if (this.attribute.description && this.attribute.description.length > 0) {
				check_arr.description = true;
			}
			if (this.attribute.namespace_id && this.attribute.namespace_id.length > 0) {
				check_arr.namespace = true;
			}

			this.valid = check_arr.name && check_arr.code && check_arr.namespace && check_arr.description;
		},

		onScopeAddClick({option, addTag}) {
			// если скоуп уже добавлен к группе - возврат
			if (this.attribute.scopes.findIndex(x => x === option) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'info', title: 'Информация', message: 'Область видимости уже добавлена'});
				return;
			}
			let id = this.scopeOptions.findIndex(x => x === option);
			if (id > -1) {
				this.scopeOptions.splice(id, 1);
			}

			this.attribute.scopes.push(option);
			addTag(option);
			this.searchScopes = '';
		},

		onScopeDeleteClick({option, removeTag}) {
			let idAddedScope = this.attribute.scopes.findIndex(x => x === option);
			if (idAddedScope > -1) {
				this.attribute.scopes.splice(idAddedScope, 1);
			}
			this.scopeOptions.push(option);
			this.scopeOptions.sort((a, b) => a.normalize().localeCompare(b.normalize()));

			removeTag(option);
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.attribute[name];
			return $dirty ? !$error : null;
		},

		backToAttributeTable() {
			this.$router.push({name: routeNames.passport.attributes.read});
		},

		async submit() {
			this.$v.attribute.$touch();
			if (this.$v.attribute.$anyError) {
				return;
			}
			await this.CREATE_ATTRIBUTE(this.attribute).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Атрибут успешно создан'});
				this.backToAttributeTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании атрибута'
				});
			});
		},
	},

	computed: {
		...mapGetters([
			'passportSU', 'passportNamespaces', 'passportScopes'
		]),
		// Функции для работы поиска
		scopesCriteria() {
			return this.searchScopes.trim().toLowerCase();
		},
		scopeAvailableOptions() {
			const criteria = this.scopesCriteria;
			if (criteria) {
				return this.scopeOptions.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
			}
			return this.scopeOptions;
		},
		searchScopesDesc() {
			if (this.scopesCriteria && this.scopeAvailableOptions.length === 0) {
				return 'Нет областей видимости с заданным именем';
			}
			return '';
		},
	},

	async mounted() {
		await this.GET_NAMESPACES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка неймспейсов'
			});
		});
		for (let i = 0; i < this.passportNamespaces.length; i++) {
			this.namespaceOptions.push({
				value: this.passportNamespaces[i].id,
				text: this.passportNamespaces[i].name + ' - ' + this.passportNamespaces[i].code
			});
		}
		this.attribute.namespace_id = this.namespaceOptions[0].value;

		await this.GET_SCOPES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка областей видимости'
			});
		});
		for (let i = 0; i < this.passportScopes.length; i++) {
			this.scopeOptions.push(this.passportScopes[i].code);
		}

		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
