<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица атрибутов пользователей
		</h5>
		<div class="form-desc">
			Выберите нужный атрибут и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.passport.attributes.create }">Создать новый аттрибут</router-link>
		</div>
		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация атрибутов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="attributes-id-filter-group" label="ID"
															label-for="attributes-id-filter">
									<b-form-input
											id="attributes-id-filter"
											v-model="filters.id"
											placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>
								<b-form-group id="attributes-namespace-filter-group" label="Неймспейс"
															label-for="attributes-namespace-filter">
									<b-form-select
											id="attributes-namespace-filter"
											v-model="filters.namespace_id"
											:options="namespaceOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group id="attributes-scope-filter-group" label="Области видимости"
															label-for="attributes-scope-filter">
									<b-form-tags
											id="attributes-scope-filter"
											v-model="filters.scopes"
											placeholder="Введите области"
									></b-form-tags>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="passport-attributes-get-limit"
									name="passport-attributes-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="GET_ATTRIBUTES_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Название
					</th>
					<th>
						Код
					</th>
                    <th>
                        Описание
                    </th>
					<th class="text-right">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="attribute in passportAttributes" :key="attribute.id">
				<tr>
					<td>
						{{ attribute.name }}
					</td>
					<td class="text-left">
						{{ attribute.code }}
					</td>
                    <td v-b-popover.hover.top="attribute.description">
                        {{ attribute.description }}
                    </td>
					<td class="row-actions text-right">
						<router-link v-if="canUpdatePassportAttributes || passportSU" v-b-tooltip.html.top
												 title="Редактировать"
												 :to="{ name: routeNames.passport.attributes.update, params: { attribute_id: attribute.id }} ">
							<i class="os-icon os-icon-ui-49"></i>
						</router-link>

						<a v-if="canDeletePassportAttributes || passportSU" v-b-tooltip.html.top title="Удалить"
							 @click="showDeleteQuestion(attribute)" class="danger">
							<i class="os-icon os-icon-ui-15"></i>
						</a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next"
							 v-if="canGetNext && passportAttributes.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'AttributeTable',
	data() {
		return {
			filters: {
				id: null,
				namespace_id: null,
				scopes: [],
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Атрибутов на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],
			deleteCheck: false,
			canGetNext: true,
			canGetPrev: false,

			namespaceOptions: [{value: null, text: 'Выберите нужный неймспейс'}],
			routeNames
		};
	},
	computed: {
		...mapGetters([
			'passportAttributes', 'canUpdatePassportAttributes', 'canDeletePassportAttributes', 'passportNamespaces',
			'passportSU'
		]),
	},
	methods: {
		...mapActions([
			'GET_ATTRIBUTES_FROM_API', 'GET_NAMESPACES_FROM_API', 'DELETE_ATTRIBUTE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getAttributes();
		},

		showDeleteQuestion(attribute) {
			this.deleteCheck = false;
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить атрибут ' + attribute.name + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.DELETE_ATTRIBUTE({id: attribute.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'success',
							title: 'Успех',
							message: 'Атрибут успешно удалён'
						});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении атрибута'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getAttributes();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getAttributes();
			if (this.passportAttributes.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getAttributes() {
			await this.GET_ATTRIBUTES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка атрибутов'
				});
			});
		},
	},

	async mounted() {
		this.namespaceOptions.push({value: null, text: 'Выберите неймспейс, в котором вы ищете атрибут'});
		await this.GET_NAMESPACES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка неймспейсов'
			});
		});
		for (let i = 0; i < this.passportNamespaces.length; i++) {
			this.namespaceOptions.push({
				value: this.passportNamespaces[i].id,
				text: this.passportNamespaces[i].name + ' - ' + this.passportNamespaces[i].code
			});
		}

		await this.getAttributes();
	},
};
</script>

<style>

</style>
