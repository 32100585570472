<template>
	<div class="element-box">
		<b-form @submit.stop.prevent="submit">
			<h5 class="form-header">
				Создание группы прав доступа
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Название группы: {{ group.name }}<br>
					Код группы: {{ group.code }}<br>
					<div v-if="namespaceOptions.length > 0 && group.namespace_id !== ''">
						Неймспейс группы: {{ namespaceOptions.find(x => x.value === group.namespace_id).text }}<br>
					</div>
					<div v-if="group.permissions.length > 0">
						Права группы:
						<div v-for="perm in group.permissions" :key="perm.id">{{ perm.name }}</div>
					</div>
					<div v-if="group.scopes.length > 0">
						Области видимости группы:
						<div v-for="scope in group.scopes" :key="scope">{{ scope }}</div>
					</div>
				</div>
			</b-modal>

			<b-form-group id="name-input-group" label="Название группы" label-for="group-name-input">
				<b-form-input
						id="group-name-input"
						v-model="$v.group.name.$model"
						:state="validateState('name')"
						aria-describedby="name-input-group-feedback"
						placeholder="Введите название группы прав"
				></b-form-input>
				<b-form-invalid-feedback
						id="name-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="code-input-group" label="Код группы" label-for="group-code-input">
				<b-form-input
						id="group-code-input"
						v-model="$v.group.code.$model"
						:state="validateState('code')"
						aria-describedby="code-input-group-feedback"
						placeholder="Введите код группы прав"
				></b-form-input>
				<b-form-invalid-feedback
						id="code-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="description-input-group" label="Описание группы прав" label-for="description-code-input">
				<b-form-textarea
						id="group-description-input"
						v-model="$v.group.description.$model"
						:state="validateState('description')"
						aria-describedby="description-input-group-feedback"
						placeholder="Введите описание группы прав"
				></b-form-textarea>
				<b-form-invalid-feedback
						id="description-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="namespace-input-group" label="Неймспейс группы" label-for="namespace-input">
				<b-form-select
						id="namespace-input"
						v-model="group.namespace_id"
						:options="namespaceOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="namespace-select-group" label="Неймспейс добавляемых прав доступа"
										label-for="namespace-select">
				<b-form-select
						id="namespace-select"
						v-model="namespace"
						:options="namespaceOptions"
						@change="reloadNamespacedParameters"
				></b-form-select>
			</b-form-group>

			<b-form-group id="permissions-input-group" label="Права группы" label-for="user-permissions-input">
				<b-form-tags id="user-permissions-input" v-model="groupPermissions" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onPermissionDeleteClick({option: tag, removeTag: removeTag})"
										:disabled="disabled"
										variant="info"
								>{{ JSON.parse(tag).name }} - {{ JSON.parse(tag).code }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block
												menu-class="w-100">
							<template #button-content>
								Добавление прав
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск прав"
										label-for="permission-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchPermissionsDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchPermissions"
											id="permission-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название права доступа"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="permission in permissionAvailableOptions"
									:key="permission.code"
									@click="onPermissionAddClick({ option: permission, addTag: addTag })"
							>{{ permission.name }} - {{ permission.code }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="permissionAvailableOptions.length === 0">
								Нет прав доступа, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<b-form-group id="scopes-input-group" label="Области видимости права доступа"
										label-for="permission-scopes-input">
				<b-form-tags id="permission-scopes-input" v-model="group.scopes" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onScopeDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>{{ tag }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block
												menu-class="w-100">
							<template #button-content>
								Добавление областей видимости
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск областей видимости"
										label-for="scope-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchScopesDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchScopes"
											id="scope-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название группы прав"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="scope in scopeAvailableOptions"
									:key="scope"
									@click="onScopeAddClick({ option: scope, addTag: addTag })"
							>{{ scope }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="scopeAvailableOptions.length === 0">
								Нет областей видимости, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">
					Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToGroupTable()">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

export default {
	name: 'GroupCreateForm',
	mixins: [validationMixin],
	validations: {
		group: {
			name: {
				required
			},
			code: {
				required
			},
			description: {
				required
			}
		}
	},

	data() {
		return {
			group: {
				name: '',
				code: '',
				description: '',
				namespace_id: '',
				permissions: [],
				scopes: [],
			},
			groupPermissions: [],
			permissionOptions: [],
			searchPermissions: '',

			scopeOptions: [],
			searchScopes: '',

			namespaceOptions: [],
			namespace: '',

			valid: false,
		};
	},
	watch: {
		'group': function (val, oldVal) {
			this.checkFields();
		},
		'group.scopes': function (val, oldVal) {
			this.checkFields();
		},
		'groupPermissions': function (val, oldVal) {
			this.checkFields();
		},
	},

	methods: {
		...mapActions([
			'CREATE_GROUP', 'GET_GROUPS_FROM_API', 'GET_PERMISSIONS_FROM_API', 'GET_SCOPES_FROM_API', 'GET_NAMESPACES_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY'
		]),
		checkFields() {
			let check_arr = {
				name: false,
				code: false,
				description: false,
				permission: false
			};
			if (this.group.name && this.group.name.length > 0) {
				check_arr.name = true;
			}
			if (this.group.code && this.group.code.length > 0) {
				check_arr.code = true;
			}
			if (this.group.description && this.group.description.length > 0) {
				check_arr.description = true;
			}
			if (this.groupPermissions && this.groupPermissions.length > 0) {
				check_arr.permission = true;
			}
			this.valid = check_arr.permission && check_arr.name && check_arr.code && check_arr.description;
		},

		onPermissionAddClick({option, addTag}) {
			// если право уже добавлено к группе - возврат
			if (this.group.permissions.findIndex(x => x.permission === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Право доступа уже добавлено'
				});
				return;
			}
			let id = this.permissionOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.permissionOptions.splice(id, 1);
			}

			this.group.permissions.push({permission: option.id, name: option.name});
			addTag(JSON.stringify(option));
			this.searchPermissions = '';
		},

		onPermissionDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let permissionToRemove = JSON.parse(option);
			let idAddedPermission = this.group.permissions.findIndex(x => x.permission === permissionToRemove.id);
			if (idAddedPermission > -1) {
				this.group.permissions.splice(idAddedPermission, 1);
			}

			this.permissionOptions.push(permissionToRemove);
			this.permissionOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
			removeTag(option);
		},

		onScopeAddClick({option, addTag}) {
			// если скоуп уже добавлен к группе - возврат
			if (this.group.scopes.findIndex(x => x === option) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Область видимости уже добавлена'
				});
				return;
			}
			let id = this.scopeOptions.findIndex(x => x === option);
			if (id > -1) {
				this.scopeOptions.splice(id, 1);
			}

			this.group.scopes.push(option);
			addTag(option);
			this.searchScopes = '';
		},

		onScopeDeleteClick({option, removeTag}) {
			let idAddedScope = this.group.scopes.findIndex(x => x === option);
			if (idAddedScope > -1) {
				this.group.scopes.splice(idAddedScope, 1);
			}
			this.scopeOptions.push(option);
			this.scopeOptions.sort((a, b) => a.normalize().localeCompare(b.normalize()));
			removeTag(option);
		},

		async reloadNamespacedParameters() {
			await this.GET_PERMISSIONS_FROM_API({namespace_id: this.namespace, limit: 1000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка прав доступа'
				});
			});
			this.permissionOptions = this.passportPermissions;
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.group[name];
			return $dirty ? !$error : null;
		},

		backToGroupTable() {
			this.$router.push({name: routeNames.passport.groups.read});
		},

		async submit() {
			this.$v.group.$touch();
			if (this.$v.group.$anyError) {
				return;
			}
			await this.CREATE_GROUP(this.group).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Группа прав доступа успешно создана'
				});
				this.backToGroupTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании группы прав доступа'
				});
			});
		},
	},

	computed: {
		...mapGetters([
			'passportPermissions', 'passportGroups', 'passportSU', 'passportNamespaces', 'passportScopes'
		]),
		// Функции для работы поиска
		permissionsCriteria() {
			return this.searchPermissions.trim().toLowerCase();
		},
		permissionAvailableOptions() {
			const criteria = this.permissionsCriteria;
			if (criteria) {
				return this.permissionOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.permissionOptions;
		},
		searchPermissionsDesc() {
			if (this.permissionsCriteria && this.permissionAvailableOptions.length === 0) {
				return 'Нет прав доступа с заданным именем';
			}
			return '';
		},
		scopesCriteria() {
			return this.searchScopes.trim().toLowerCase();
		},
		scopeAvailableOptions() {
			const criteria = this.scopesCriteria;
			if (criteria) {
				return this.scopeOptions.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
			}
			return this.scopeOptions;
		},
		searchScopesDesc() {
			if (this.scopesCriteria && this.scopeAvailableOptions.length === 0) {
				return 'Нет областей видимости с заданным именем';
			}
			return '';
		},
	},

	async mounted() {
		await this.GET_NAMESPACES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка неймспейсов'
			});
		});
		for (let i = 0; i < this.passportNamespaces.length; i++) {
			this.namespaceOptions.push({
				value: this.passportNamespaces[i].id,
				text: this.passportNamespaces[i].name + ' - ' + this.passportNamespaces[i].code
			});
		}
		this.namespace = this.namespaceOptions[0].value;
		this.group.namespace_id = this.namespaceOptions[0].value;
		await this.reloadNamespacedParameters();

		await this.GET_SCOPES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка областей видимости'
			});
		});
		for (let i = 0; i < this.passportScopes.length; i++) {
			this.scopeOptions.push(this.passportScopes[i].code);
		}

		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
