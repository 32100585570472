<template>
	<div class="element-box">
		<b-form @submit.stop.prevent="submit" class="user-update-form">
			<h5 class="form-header">
				Создание пользователя
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Логин пользователя: {{ user.login }}<br>
					Имя пользователя: {{ user.name }}<br>
					<div v-if="user.userPermissions.length > 0">
						Права пользователя:
						<div v-for="perm in user.userPermissions" :key="perm.id">{{ perm.name }}</div>
					</div>
					<div v-if="user.groups.length > 0">
						Группы пользователя:
						<div v-for="group in user.groups" :key="group">{{ group }}</div>
					</div>
				</div>
			</b-modal>

			<b-form-group id="login-input-group" label="Логин пользователя" label-for="user-login-input">
				<b-form-input
						id="user-login-input"
						v-model="$v.user.login.$model"
						:state="validateState('login')"
						aria-describedby="login-input-group-feedback"
						placeholder="Введите логин пользователя"
				></b-form-input>
				<b-form-invalid-feedback
						id="login-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="password-input-group" label="Пароль пользователя" label-for="user-password-input">
				<b-form-input
						id="user-password-input"
						v-model="user.password"
						placeholder="Введите пароль пользователя"
				></b-form-input>
			</b-form-group>

			<b-form-group label="VPN логин пользователя"
										v-show="canUseHelpdeskVpnApi && !IS_EXTERNAL() && this.user.is_service === 0"
										label-for="user-vpn-login-input">
				<b-input-group>
					<b-form-input
							id="user-vpn-login-input"
							v-model="user.login"
							disabled
					></b-form-input>
					<b-input-group-append>
						<b-button @click="copyToClipBoard(user.login)"
											class="os-icon os-icon-copy btn-grey"></b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>

			<b-form-group label="VPN пароль пользователя"
										v-show="canUseHelpdeskVpnApi && !IS_EXTERNAL() && this.user.is_service === 0"
										label-for="user-vpn-pass-input">
				<b-input-group>
					<b-form-input
							id="user-vpn-pass-input"
							v-model="user.vpnPass"
							disabled
					></b-form-input>
					<b-input-group-append>
						<b-button @click="copyToClipBoard(user.vpnPass)"
											class="os-icon os-icon-copy btn-grey"></b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>

			<b-form-group label="ID аккаунта в telegram" v-show="!IS_EXTERNAL() && this.user.is_service === 0"
										label-for="user-tg-id-input">
				<b-form-input
						id="user-tg-id-input"
						v-model="$v.user.tgId.$model"
						placeholder="123456789"
						:state="validateState('tgId')"
						aria-describedby="tg-id-group-feedback"
				></b-form-input>
				<b-form-invalid-feedback
						id="tg-id-group-feedback"
				>Обязательное поле. Только цифры
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group label="Логин аккаунта в telegram" v-show="!IS_EXTERNAL() && this.user.is_service === 0"
										label-for="user-tg-login-input">
				<b-form-input
						id="user-tg-login-input"
						v-model="$v.user.tgLogin.$model"
						placeholder="@nickname"
						:state="validateState('tgLogin')"
						aria-describedby="tg-login-group-feedback"
				></b-form-input>
				<b-form-invalid-feedback
						id="tg-login-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="name-input-group" label="Имя пользователя" label-for="user-name-input">
				<b-form-input
						id="user-name-input"
						v-model="$v.user.name.$model"
						:state="validateState('name')"
						aria-describedby="name-input-group-feedback"
						placeholder="Введите имя пользователя"
				></b-form-input>
				<b-form-invalid-feedback
						id="name-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group label="Сервисный пользователь?" v-show="!IS_EXTERNAL()" label-for="user-service-input">
				<b-form-checkbox
						id="user-service-input"
						v-model="user.is_service"
						:value="1"
						:unchecked-value="0"
				></b-form-checkbox>
			</b-form-group>

			<b-form-group label="Сервис пользователя" v-show="user.is_service === 1" label-for="user-system-input">
				<b-form-input
						id="user-system-input"
						v-model="user.system"
						aria-describedby="system-input-group-feedback"
						placeholder="Введите название сервиса, к которому относится пользователь"
				></b-form-input>
				<b-form-invalid-feedback
						id="system-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="attribute-input-group" label="Атрибуты пользователя" label-for="user-attribute-input">
				<b-modal
						id="attributes-modal"
						v-model="attributeShow"
						size="lg"
						title="Изменение атрибутов пользователя"
						ok-title="Применить"
						cancel-title="Отмена"
						@cancel="attributeRefresh"
				>
					<b-row class="mb-1 text-center">
						<b-col>Атрибут</b-col>
						<b-col>Значение</b-col>
						<b-col cols="2"></b-col>
					</b-row>
					<div v-for="attr in user.userAttributes" :key="attr.attribute">
						<b-row align-v="center" class="mb-1 text-center">
							<b-col>
								<b-select v-model="attr.attribute">
									<b-select-option v-for="(opt, i) in attributeOptions" :key="i" :value="opt.value" :title="opt.title">
										{{ opt.text }}
									</b-select-option>
								</b-select>
							</b-col>
							<b-col>
								<b-input v-model="attr.value"></b-input>
							</b-col>
							<b-col cols="2">
								<b-button class="btn btn-danger" @click="attributeDelete(attr)">Удалить</b-button>
							</b-col>
						</b-row>
					</div>
					<b-button class="btn btn-grey" @click="attributeAdd">Добавить</b-button>
				</b-modal>
				<b-button id="user-attribute-input" class="btn btn-grey" @click="attributeShow=true">Атрибуты</b-button>
			</b-form-group>

			<b-form-group label="Доступ к офисам" 
				v-show="!IS_EXTERNAL() && this.user.is_service === 0" 
				id="branches-input-group" 
				label-for="user-branches-input"
			>
				<b-form-tags id="user-branches-input" v-model="userBranches" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onBranchDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>
									{{ JSON.parse(tag).name }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление офисов
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск офисов"
										label-for="branch-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchBranchDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchBranches"
											id="branch-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название офиса"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="branch in branchAvailableOptions"
									:key="branch.id"
									@click="onBranchAddClick({ option: branch, addTag: addTag })"
							>{{ branch.name }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="branchAvailableOptions.length === 0">
								Нет прав доступа, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<b-form-group id="namespace-select-group" label="Выбор неймспейса групп и прав доступа"
										label-for="namespace-select">
				<b-form-select
						id="namespace-select"
						v-model="namespace"
						:options="namespaceOptions"
						@change="reloadNamespacedParameters"
				></b-form-select>
			</b-form-group>

			<b-form-group id="groups-input-group" label="Группы прав пользователя" label-for="user-groups-input">
				<b-form-tags id="user-groups-input" v-model="userGroups" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onGroupDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>{{ JSON.parse(tag).name }} - {{ JSON.parse(tag).code }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block
												menu-class="w-100">
							<template #button-content>
								Добавление групп
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск групп"
										label-for="group-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchGroupsDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchGroups"
											id="group-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название группы прав"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="group in groupAvailableOptions"
									:key="group.code"
									:title="group.description"
									@click="onGroupAddClick({ option: group, addTag: addTag })"
							>{{ group.name }} - {{ group.code }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="groupAvailableOptions.length === 0">
								Нет групп прав, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<b-form-group id="permissions-input-group" label="Права пользователя" label-for="user-permissions-input">
				<b-form-tags id="user-permissions-input" v-model="userPermissions" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onPermissionDeleteClick({option: tag, removeTag: removeTag})"
										:disabled="disabled"
										variant="info"
								>
									<b-form-checkbox
											v-model="JSON.parse(tag).exclude"
											value="0"
											unchecked-value="1"
											inline
											plain
											@change="onPermissionExcludeChange({ option: tag })"
									></b-form-checkbox>
									{{ JSON.parse(tag).name }} - {{ JSON.parse(tag).code }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block
												menu-class="w-100">
							<template #button-content>
								Добавление прав
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск прав"
										label-for="permission-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchPermissionsDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchPermissions"
											id="permission-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название права доступа"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="permission in permissionAvailableOptions"
									:key="permission.code"
									:title="permission.description"
									@click="onPermissionAddClick({ option: permission, addTag: addTag })"
							>{{ permission.name }} - {{ permission.code }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="permissionAvailableOptions.length === 0">
								Нет прав доступа, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">
					Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToUserTable()">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, requiredIf} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';
import generator from 'generate-password';
import {
	IS_EXTERNAL,
	VUE_APP_PASSPORT_TG_ID_ATTR,
	VUE_APP_PASSPORT_TG_LOGIN_ATTR,
	VUE_APP_PASSPORT_VPN_ATTR,
	VUE_APP_PASSPORT_BRANCHES_ATTR
} from '@/config';

export default {
	name: 'UserCreateForm',
	mixins: [validationMixin],
	validations: {
		user: {
			login: {
				required
			},
			name: {
				required
			},
			tgId: {
				required: requiredIf(function () {
					return !IS_EXTERNAL() && this.user.is_service === 0;
				}),
			},
			tgLogin: {
				required: requiredIf(function () {
					return !IS_EXTERNAL() && this.user.is_service === 0;
				}),
			}
		}
	},
	data() {
		return {
			user: {
				login: '',
				password: '',
				name: '',
				disabled: 0,
				is_service: 0,
				system: null,
				vpnPass: this.genPass(),
				tgId: '',
				tgLogin: '',
				groups: [],
				userPermissions: [],
				userAttributes: []
			},
			userPermissions: [],
			permissionOptions: [],
			searchPermissions: '',

			userGroups: [],
			groupOptions: [],
			searchGroups: '',

			valid: false,

			attributeShow: false,
			attributeOptions: [],
			attributeError: [],

			userBranches: [],
			userOriginalBranches: [],
			branchOptions: [],
			searchBranches: '',
		};
	},

	watch: {
		'user': {
			deep: true,
			handler(currUser) {
				this.checkFields(currUser);
			}
		}
	},

	methods: {
		IS_EXTERNAL,
		...mapActions([
			'CREATE_USER', 'GET_USERS_FROM_API', 'GET_PERMISSIONS_FROM_API', 'GET_GROUPS_FROM_API', 'GET_NAMESPACES_FROM_API',
			'GET_ATTRIBUTES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY', 'HELPDESK_CREATE_VPN_USER', 'HELPDESK_GET_BRANCHES_FROM_API'
		]),
		checkFields() {
			let check_form = {
				login: false,
				password: false,
				name: false,
				tgLogin: false,
				tgId: false,
				attributes: true
			};

			if (this.user.login && this.user.login.length > 0) {
				check_form.login = true;
			}
			if (this.user.password && this.user.password.length > 0) {
				check_form.password = true;
			}
			if (this.user.name && this.user.name.length > 0) {
				check_form.name = true;
			}
			if ((Array.from(this.user.tgLogin)[0] === '@' && this.user.tgLogin.length > 1) || this.user.tgLogin === '-') {
				check_form.tgLogin = true;
			}
			if (this.user.tgId.length > 1 || this.user.tgId === '-') {
				check_form.tgId = true;
			}
			if (IS_EXTERNAL() || this.user.is_service) {
				check_form.tgLogin = true;
				check_form.tgId = true;
			}

			let app = this;
			if (this.user.userAttributes && this.user.userAttributes.length > 0) {
				app.user.userAttributes.forEach(function (attr) {
					if (attr.attribute === null || attr.value === null || attr.value === '') {
						check_form.attributes = false;
					}
				});
			}
			this.valid = check_form.login && check_form.password && check_form.name && check_form.tgLogin && check_form.tgId && check_form.attributes;
		},

		copyToClipBoard(value) {
			window.navigator.clipboard.writeText(value);
		},

		onPermissionAddClick({option, addTag}) {
			// если право уже добавлено к пользователю - возврат
			if (this.user.userPermissions.findIndex(x => x.permission === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Право доступа уже добавлено'
				});
				return;
			}
			this.user.userPermissions.push({permission: option.id, exclude: option.exclude, name: option.name});

			let id = this.permissionOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.permissionOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchPermissions = '';
		},

		onPermissionDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let permissionToRemove = JSON.parse(option);

			let idAddedPermission = this.user.userPermissions.findIndex(x => x.permission === permissionToRemove.id);
			if (idAddedPermission > -1) {
				this.user.userPermissions.splice(idAddedPermission, 1);
			}
			this.permissionOptions.push(permissionToRemove);
			this.permissionOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
			removeTag(option);
		},

		onPermissionExcludeChange({option}) {
			let permissionToChangeExclude = JSON.parse(option);

			let idExcludeChangedPermission = this.user.userPermissions.findIndex(x => x.permission === permissionToChangeExclude.id);
			if (idExcludeChangedPermission > -1) {
				this.user.userPermissions[idExcludeChangedPermission].exclude = Number(!this.user.userPermissions[idExcludeChangedPermission].exclude);
			}
		},

		onBranchAddClick({option, addTag}) {
			if (this.userBranches.findIndex(x => x.id === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Офис уже добавлен'
				});
				return;
			}

			this.userBranches.push({id: option.id, name: option.name});

			let id = this.branchOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.branchOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchBranches = '';
		},

		onBranchDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let branchToRemove = JSON.parse(option);

			let isAddedBranch = this.userBranches.findIndex(x => x.id === branchToRemove.id);
			if (isAddedBranch > -1) {
				this.userBranches.splice(isAddedBranch, 1);
			}

			this.branchOptions.push(branchToRemove);
			this.branchOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));

			removeTag(option);
		},

		onGroupAddClick({option, addTag}) {
			// если группа уже добавлена к пользователю - возврат
			if (this.user.groups.findIndex(x => x === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Группа прав доступа уже добавлена'
				});
				return;
			}
			this.user.groups.push(option.id);

			let id = this.groupOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.groupOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchGroups = '';
		},

		onGroupDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let groupToRemove = JSON.parse(option);

			let idAddedGroup = this.user.groups.findIndex(x => x === groupToRemove.id);
			if (idAddedGroup > -1) {
				this.user.groups.splice(idAddedGroup, 1);
			}
			this.groupOptions.push(groupToRemove);
			this.groupOptions.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
			removeTag(option);
		},

		attributeAdd() {
			let app = this;
			this.user.userAttributes.forEach(function (attr) {
				let idAddedAttr = app.attributeOptions.findIndex(x => x.value === attr.attribute);
				if (idAddedAttr > -1) {
					app.attributeOptions[idAddedAttr].disabled = true;
				}
			});
			let i = this.user.userAttributes.length;
			if (i === 0 || this.user.userAttributes[i - 1].attribute !== null && this.user.userAttributes[i - 1].value !== null) {
				this.user.userAttributes.push({attribute: null, value: null});
			}
		},

		attributeDelete(attr) {
			let idAddedAttr = this.user.userAttributes.findIndex(x => x.attribute === attr.attribute);
			if (idAddedAttr > -1) {
				this.user.userAttributes.splice(idAddedAttr, 1);
			}
			let app = this;
			this.user.userAttributes.forEach(function (attr) {
				let idAddedAttr = app.attributeOptions.findIndex(x => x.value === attr.attribute);
				if (idAddedAttr > -1) {
					app.attributeOptions[idAddedAttr].disabled = false;
				}
			});
		},

		attributeRefresh() {
			this.user.userAttributes = [];
		},

		async addUserBranches() {
			if (this.userBranches && this.userBranches.length > 0) {
				let values = [];
				this.userBranches.forEach(function callback(userBranch) {
					let branch = userBranch;
					if (typeof branch != 'object') {
						branch = JSON.parse(branch);
					}
					values.push(branch.id);
				});
				this.attributeDelete({attribute: VUE_APP_PASSPORT_BRANCHES_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_BRANCHES_ATTR, value: values.join(',')});
			}
		},

		async getNamespaces() {
			await this.GET_NAMESPACES_FROM_API({}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка неймспейсов'
				});
			});
			for (let i = 0; i < this.passportNamespaces.length; i++) {
				this.namespaceOptions.push({
					value: this.passportNamespaces[i].id,
					text: this.passportNamespaces[i].name + ' - ' + this.passportNamespaces[i].code
				});
			}
			this.namespace = this.namespaceOptions[0].value;
		},

		async getAttributes() {
			await this.GET_ATTRIBUTES_FROM_API({}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка атрибутов'
				});
			});
			for (let i = 0; i < this.passportAttributes.length; i++) {
				if (!IS_EXTERNAL() && (this.passportAttributes[i].id === VUE_APP_PASSPORT_VPN_ATTR ||
						this.passportAttributes[i].id === VUE_APP_PASSPORT_TG_ID_ATTR ||
						this.passportAttributes[i].id === VUE_APP_PASSPORT_TG_LOGIN_ATTR)) {
					continue;
				}
				this.attributeOptions.push({
					value: this.passportAttributes[i].id,
					text: this.passportAttributes[i].name + ' - ' + this.passportAttributes[i].code,
					title: this.passportAttributes[i].description
				});
			}
		},

		async getBranches() {
			await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000, project: 'all'}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов'
				});
			});

			for (let i = 0; i < this.helpdeskBranches.length; i++) {
				this.branchOptions.push({
					id: this.helpdeskBranches[i].id,
					name: this.helpdeskBranches[i].name
				});
			}
		},

		async getNamespacedPermissions() {
			await this.GET_PERMISSIONS_FROM_API({limit: 5000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка прав доступа'
				});
			});
			this.permissionOptions = this.passportPermissions;
			this.permissionOptions.forEach(function (itm) {
				itm.exclude = 0;
			});

			for (let i = 0; i < this.permissionOptions.length; i += 1) {
				let ns = this.passportNamespaces.find(n => n.id === this.permissionOptions[i].namespace_id) || '';
				this.permissionOptions[i].namespace_name = ns.name;
			}

			this.permissionOptions.sort((a, b) => a.namespace_name.normalize().localeCompare(b.namespace_name.normalize()));
		},

		async getNamespacedGroups() {
			await this.GET_GROUPS_FROM_API({limit: 1000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка групп прав доступа'
				});
			});
			this.groupOptions = this.passportGroups;
			for (let i = 0; i < this.groupOptions.length; i += 1) {
				let ns = this.passportNamespaces.find(n => n.id === this.groupOptions[i].namespace_id) || '';
				this.groupOptions[i].namespace_name = ns.name;
			}

			this.groupOptions.sort((a, b) => a.namespace_name.normalize().localeCompare(b.namespace_name.normalize()));
		},

		async reloadNamespacedParameters() {
			await Promise.all([this.getNamespacedGroups(), this.getNamespacedPermissions()]);
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.user[name];
			return $dirty ? !$error : null;
		},

		backToUserTable() {
			this.$router.push({name: routeNames.passport.users.read});
		},

		genPass() {
			return generator.generate({
				length: 16,
				numbers: true,
				excludeSimilarCharacters: true
			});
		},

		async submit() {
			this.$v.user.$touch();
			if (this.$v.user.$anyError) {
				return;
			}
			let canGo = true;
			if (!IS_EXTERNAL() && this.user.is_service === 0 && this.user.tgId && this.user.tgId.length > 0) {
				this.attributeDelete({attribute: VUE_APP_PASSPORT_TG_ID_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_TG_ID_ATTR, value: this.user.tgId});
			}
			if (!IS_EXTERNAL() && this.user.is_service === 0 && this.user.tgLogin && this.user.tgLogin.length > 0) {
				this.attributeDelete({attribute: VUE_APP_PASSPORT_TG_LOGIN_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_TG_LOGIN_ATTR, value: this.user.tgLogin});
			}
			if (!IS_EXTERNAL() && this.user.is_service === 0) {
				await this.addUserBranches();
			}
			if (this.canUseHelpdeskVpnApi && !IS_EXTERNAL() && this.user.is_service === 0) {
				this.attributeDelete({attribute: VUE_APP_PASSPORT_VPN_ATTR});
				this.user.userAttributes.push({attribute: VUE_APP_PASSPORT_VPN_ATTR, value: this.user.login});
				await this.HELPDESK_CREATE_VPN_USER({
					user: this.user.login,
					pass: this.user.vpnPass,
				}).then(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'success',
						title: 'Успех',
						message: 'Пользователь VPN успешно создан'
					});
				}).catch(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Произошла ошибка при создании пользователя VPN'
					});
					canGo = false;
				});
			}
			if (!canGo) {
				return;
			}
			await this.CREATE_USER(this.user).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Пользователь успешно создан'
				});
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании пользователя'
				});
			});
			if (!canGo) {
				return;
			}
			this.backToUserTable();
		},
	},

	computed: {
		...mapGetters([
			'passportUsers', 'passportPermissions', 'passportGroups', 'passportNamespaces', 'passportAttributes', 'canUseHelpdeskVpnApi', 'helpdeskBranches'
		]),
		// Функции для работы поиска
		permissionsCriteria() {
			return this.searchPermissions.trim().toLowerCase();
		},
		permissionAvailableOptions() {
			const criteria = this.permissionsCriteria;
			if (criteria) {
				return this.permissionOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.permissionOptions;
		},
		searchPermissionsDesc() {
			if (this.permissionsCriteria && this.permissionAvailableOptions.length === 0) {
				return 'Нет прав доступа с заданным именем';
			}
			return '';
		},
		groupsCriteria() {
			return this.searchGroups.trim().toLowerCase();
		},
		groupAvailableOptions() {
			const criteria = this.groupsCriteria;
			if (criteria) {
				return this.groupOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.groupOptions;
		},
		searchGroupsDesc() {
			if (this.groupsCriteria && this.groupAvailableOptions.length === 0) {
				return 'Нет групп с заданным именем';
			}
			return '';
		},
		branchesCriteria() {
			return this.searchBranches.trim().toLowerCase();
		},
		branchAvailableOptions() {
			const criteria = this.branchesCriteria;
			if (criteria) {
				return this.branchOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.branchOptions;
		},
		searchBranchDesc() {
			if (this.branchesCriteria && this.branchAvailableOptions.length === 0) {
				return 'Нет прав доступа с заданным именем';
			}
			return '';
		},
	},

	async mounted() {
		await Promise.all([
			this.getNamespaces(),
			this.getAttributes(),
			this.getBranches(),
			this.reloadNamespacedParameters(),
		]);
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>
.user-update-form .dropdown-menu {
	max-height: 350px !important;
	border: 1px solid #000000 !important;
}
</style>
