import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/staff/event-types';

function scheduleData(schedule) {
	return {
		userId: schedule.userId,
		dates: schedule.dates,
		beginTime: schedule.beginTime,
		endTime: schedule.endTime,
		absenceReason: schedule.absenceReason,
	};
}

function officesData(offices) {
	return {
		userId: offices.userId,
		offices: offices.offices,
	};
}

function clustersData(clusters) {
	return {
		userId: clusters.userId,
		clusters: clusters.clusters,
	};
}

export const actions = {
	async HELPDESK_GET_STAFF_SCHEDULE_FROM_API({commit}, { userId, fromDate, toDate, fromMonth, toMonth, limit, page }) {
		let filter = {
			userId: userId,
			fromDate: fromDate,
			toDate: toDate,
			fromMonth: fromMonth,
			toMonth: toMonth,
			limit: limit,
			page: page,
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/staff/schedule', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_STAFF_SCHEDULE, resp.data.data);
			return resp.data.data;
		});
	},

	async HELPDESK_DELETE_STAFF_SCHEDULE({}, { userId, scheduleId }) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/staff/schedule/'+userId+'/'+scheduleId, {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			return resp.data.data;
		});
	},

	async HELPDESK_CREATE_STAFF_SCHEDULE({}, schedule) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/staff/schedule', scheduleData(schedule), {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			return resp.data.data;
		});
	},

	async HELPDESK_GET_STAFF_OFFICES_FROM_API({commit}, { userId, officeId }) {
		let filter = {
			userId: userId,
			officeId: officeId,
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v2/staff/offices', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_STAFF_OFFICES, resp.data.data);
			return resp.data.data;
		});
	},

	async HELPDESK_SAVE_STAFF_OFFICES({}, offices) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v2/staff/offices', officesData(offices), {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			return resp.data.data;
		});
	},
	async HELPDESK_UPDATE_STAFF_OFFICES({}, offices) {
		return axios.patch(VUE_APP_HELPDESK_API_URL + '/api/v2/staff/offices', offices, {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			return resp.data.data;
		});
	},
	async HELPDESK_GET_STAFF_CLUSTERS_FROM_API({commit}, { userId, clusterId }) {
		let filter = {
			userId: userId,
			clusterId: clusterId,
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/staff/clusters', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_STAFF_CLUSTERS, resp.data.data);
			return resp.data.data;
		});
	},

	async HELPDESK_SAVE_STAFF_CLUSTERS({}, clusters) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/staff/clusters', clustersData(clusters), {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			return resp.data.data;
		});
	},
};