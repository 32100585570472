import axios from 'axios';
import {GET_PASSPORT_URL} from '@/config';
import * as events from '@/store/passport/attributes/event-types';

export const actions = {
	async GET_ATTRIBUTES_FROM_API({commit}, {id, namespace_id, scopes, limit, offset}) {
		let filter = {
			id: id,
			namespace_id: namespace_id,
			scopes: scopes,
			limit: limit,
			offset: offset,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(GET_PASSPORT_URL() + '/api/v1/attribute?limit=100', {
			params: filter
		}).then((resp) => {
			if (resp.data.attributes) {
				commit(events.PASSPORT_SET_ATTRIBUTES, resp.data.attributes);
				return resp.data.attributes;
			} else {
				commit(events.PASSPORT_SET_ATTRIBUTES, resp.data.attribute);
				return resp.data.attribute;
			}
		});
	},

	async DELETE_ATTRIBUTE({dispatch}, {id, filters}) {
		return axios.delete(GET_PASSPORT_URL() + '/api/v1/attribute?id=' + id).then(() => {
			dispatch('GET_ATTRIBUTES_FROM_API', filters);
		});
	},

	async UPDATE_ATTRIBUTE({commit}, {attribute, add, remove}) {
		let rBody = {};
		rBody.attribute = {
			id: attribute.id,
			name: attribute.name,
			description: attribute.description,
			code: attribute.code,
			namespace_id: attribute.namespace_id
		};
		if (Object.keys(add).length !== 0) {
			rBody.add = add;
		}
		if (Object.keys(remove).length !== 0) {
			rBody.remove = remove;
		}

		return axios.put(GET_PASSPORT_URL() + '/api/v1/attribute', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_ATTRIBUTES, resp.data);
			return resp.data;
		});
	},

	async CREATE_ATTRIBUTE({commit}, attribute) {
		let rBody = {};
		rBody.attribute = attribute;
		return axios.post(GET_PASSPORT_URL() + '/api/v1/attribute', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_ATTRIBUTES, resp.data);
			return resp.data;
		});
	},
};
